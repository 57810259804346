$primary-color: #aa1e2b;

* {
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: Roboto, 'IBM Plex Sans', arial, sans-serif;
  font-family: 'Segoe UI Variable', 'Segoe UI', Roboto, 'IBM Plex Sans', Oxygen,
    Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

p {
  margin-block-start: 0.35em;
  margin-block-end: 0.35em;
}

html,
body {
  padding: 0;
  margin: 0;
  // background-color: #fafafb;
  background-color: #ffffff;
  min-height: 100vh;
  font-family: Roboto, 'IBM Plex Sans', arial, sans-serif;
  font-family: 'Segoe UI Variable', 'Segoe UI', Roboto, 'IBM Plex Sans', Oxygen,
    Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
b,
strong h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
#__next,
.auth {
  min-height: 100vh;
}
.auth {
  form {
    min-height: 100vh;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
.app {
  transition: all 0.5s ease;
  padding-left: 43px;
  &.auth {
    padding: 0;
  }
  &.sideNav {
    &-true {
      padding-left: 213px;
    }
  }
}
.cui-sidenavbar {
  -webkit-transition: min-width 0.5s ease !important;
  transition: min-width 0.5s ease !important;
}
.cui-secondary-sidenavbar {
  -webkit-transition: left 0.5s ease !important;
  transition: left 0.5s ease !important;
}
.cui-sidenav-item {
  -webkit-transition: opacity 0.5s ease !important;
  transition: opacity 0.5s ease !important;
}
body {
  margin: 0;
}
main {
  margin: 10px 10px 10px 17px;
  &.auth {
    margin: 0;
  }
}
.nav-logo {
  margin-left: 10px;
}
.autocomplete-container {
  position: relative;
  z-index: 1000;
}
.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.suggestion-item,
.suggestion-item--active {
  padding: 10px;
  border-bottom: 1x solid;
  background-color: #ffffff;
}
input[type='file'] {
  padding-left: 0;
}
.pagination-bottom button {
  background: #ffffff;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.pagination-bottom input {
  height: auto;
}
.rt-table,
.rt-thead,
.rt-thead .rt-tr {
  border: none !important;
}
.rt-tbody {
  border: 1px solid #c8c9c9;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.rt-thead {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.SingleDatePicker_picker {
  z-index: 10000000 !important;
  font-family: Roboto, 'IBM Plex Sans', arial, sans-serif;
  font-family: 'Segoe UI Variable', 'Segoe UI', Roboto, 'IBM Plex Sans', Oxygen,
    Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
}
.DateInput__block {
  width: 99.9% !important;
  border: 0;
  background: transparent;
}
.SingleDatePickerInput__withBorder,
.DateRangePickerInput__withBorder {
  border-radius: 1px;
  border: 1px solid #898d8d !important;
  // width: 97.6% !important;
}
.DateRangePickerInput__withBorder {
  width: 100%;
  display: flex;
  align-items: center;
  .DateInput {
    width: auto !important;
  }
  .DateRangePickerInput_clearDates {
    margin: 0;
    border-radius: 0;
  }
}
input,
.DateInput_input {
  height: 38px;
  box-sizing: border-box;
  padding: 11px 16px;
  font-size: 14px !important;
  color: #303e47;
  font-weight: 400 !important;
}
.CalendarDayWeekNumber {
  position: absolute;
  left: -4px;
  color: #000 !important;
  border-bottom: 1px solid;
  border-top: 1px solid;
}
.CalendarMonth_table {
  width: 90%;
  margin-left: auto;
}
.DayPicker_weekHeader_ul {
  padding-left: 26px !important;
}
.DayPicker_weekHeader_li {
  width: 35px !important;
}
.react-tabs__tab {
  &[class$='--selected'] {
    color: #aa1e2be0 !important;
  }
  &[class$='--selected']:after {
    height: 3px !important;
  }
  &:focus,
  &:hover {
    &:after {
      height: 3px !important;
    }
  }
}
.SingleDatePicker_picker {
  z-index: 10000000 !important;
}
.react-select-menu {
  z-index: 10000010 !important;
}
.ReactModal__Content--after-open > div:nth-child(2),
.sc-cCsOjp {
  overflow: auto !important;
}
.teams .sc-cCsOjp,
.teams .ReactModal__Content--after-open > div:nth-child(2),
.subordinates .sc-cCsOjp,
.subordinates .ReactModal__Content--after-open > div:nth-child(2) {
  overflow: visible !important;
}
.ReactTable {
  .rt-thead {
    &.-header {
      padding-top: 0 !important;
    }
    .rt-th {
      &.-sort-desc {
        background-image: url("data:image/svg+xml,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' id='magicoon-Filled' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cg id='arrow-down-Filled'%3E%3Cpath id='arrow-down-Filled-2' data-name='arrow-down-Filled' class='cls-1' d='M26.276 19.609l-9.333 9.333a1.333 1.333 0 0 1 -1.885 0l-9.333 -9.333a1.333 1.333 0 0 1 1.885 -1.885L14.667 24.781V4a1.333 1.333 0 0 1 2.667 0V24.781l7.057 -7.057a1.333 1.333 0 0 1 1.885 1.885Z'/%3E%3C/g%3E%3C/svg%3E") !important;
      }
      &.-sort-asc {
        background-image: url("data:image/svg+xml,%3C!-- License: CC Attribution. Made by seyfdesigner: https://www.figma.com/@seyfdesigner --%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' id='magicoon-Filled' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-up%3C/title%3E%3Cg id='arrow-up-Filled'%3E%3Cpath id='arrow-up-Filled-2' data-name='arrow-up-Filled' class='cls-1' d='M19.707,10.707a1,1,0,0,1-1.414,0L13,5.414V21a1,1,0,0,1-2,0V5.414L5.707,10.707A1,1,0,0,1,4.293,9.293l7-7a1,1,0,0,1,1.414,0l7,7A1,1,0,0,1,19.707,10.707Z'/%3E%3C/g%3E%3C/svg%3E") !important;
      }
    }
  }

  &.-alternate {
    .rt-thead {
      &.-header {
        background-color: #ffffff !important;
        color: #000 !important;
        font-weight: 600;
        border-bottom: 1px solid #000 !important;
      }
      .rt-th {
        &.-sort-desc {
          background-image: url("data:image/svg+xml,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' id='magicoon-Filled' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23000000;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cg id='arrow-down-Filled'%3E%3Cpath id='arrow-down-Filled-2' data-name='arrow-down-Filled' class='cls-1' d='M26.276 19.609l-9.333 9.333a1.333 1.333 0 0 1 -1.885 0l-9.333 -9.333a1.333 1.333 0 0 1 1.885 -1.885L14.667 24.781V4a1.333 1.333 0 0 1 2.667 0V24.781l7.057 -7.057a1.333 1.333 0 0 1 1.885 1.885Z'/%3E%3C/g%3E%3C/svg%3E") !important;
        }
        &.-sort-asc {
          background-image: url("data:image/svg+xml,%3C!-- License: CC Attribution. Made by seyfdesigner: https://www.figma.com/@seyfdesigner --%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' id='magicoon-Filled' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23000000;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-up%3C/title%3E%3Cg id='arrow-up-Filled'%3E%3Cpath id='arrow-up-Filled-2' data-name='arrow-up-Filled' class='cls-1' d='M19.707,10.707a1,1,0,0,1-1.414,0L13,5.414V21a1,1,0,0,1-2,0V5.414L5.707,10.707A1,1,0,0,1,4.293,9.293l7-7a1,1,0,0,1,1.414,0l7,7A1,1,0,0,1,19.707,10.707Z'/%3E%3C/g%3E%3C/svg%3E") !important;
        }
      }
    }
    .rt-tbody {
      border: none !important;
      .rt-tr:not(.-padRow) {
        .rt-td:last-child {
          opacity: 0;
          transition: all 0.2s ease-in-out;
          button {
            border-radius: 30px;
          }
        }
        &:hover,
        &:focus {
          .rt-td:last-child {
            opacity: 1;
          }
        }
      }
    }
  }
}

.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}

[data-item-title='true'] {
  white-space: normal !important;
}

[role='tabpanel'] {
  flex-direction: column !important;
}
